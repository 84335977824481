:root {
  --page-width: 100%;
  --border-color: #d7d7d7;
  --muiTextColor: #1976d2;
  --aboutUsVerticalInterval: 130px;
  --darkModeTextColor: #b8b8b8;
  --darkModeBgColor: #181a1a;
  --darkModeActiveLinkColor: #90caf9;
  --darkModeBorderColor: #434343;
}
#root {
  margin: 0 auto;
  width: var(--page-width);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
body,
html {
  font-family: Lato;
  color: #23282f;
  transition: 200ms linear;
}
body::-webkit-scrollbar {
  display: none !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.themeToggle label {
  margin: 0 auto;
}
sup,
sub {
  font-size: 10px;
}
/* DARK MODE STARTS*/
.dark-mode {
  background-color: var(--darkModeBgColor) !important;
  color: var(--darkModeTextColor) !important;
  transition: 200ms linear !important;
}
.dark-mode .appHeader {
  border-bottom: 1px solid var(--darkModeBorderColor) !important;
}
.dark-mode footer {
  border-top: 1px solid var(--darkModeBorderColor) !important;
}
.dark-mode a {
  color: var(--darkModeTextColor) !important;
  transition: 500ms linear;
}
.dark-mode .logo {
  color: var(--darkModeTextColor) !important;
}
.dark-mode .logo img {
  filter: invert(75%) sepia(0%) saturate(4632%) hue-rotate(9deg) brightness(107%) contrast(72%);
}
.dark-mode footer img {
  filter: invert(75%) sepia(0%) saturate(4632%) hue-rotate(9deg) brightness(107%) contrast(72%);
}
.dark-mode .css-loysys {
  background-color: inherit;
}
.dark-mode .aboutUs .left {
  background-color: var(--darkModeBgColor) !important;
  border-bottom: 2px solid var(--darkModeActiveLinkColor) !important;
}
.dark-mode .aboutUs .center {
  background-color: var(--darkModeBgColor) !important;

  color: var(--darkModeActiveLinkColor) !important;
}
.dark-mode .aboutUs .right {
  background-color: var(--darkModeBgColor) !important;
  border-bottom: 2px solid var(--darkModeActiveLinkColor) !important;
}
.dark-mode .activeClass a {
  color: var(--darkModeActiveLinkColor) !important;
}
.dark-mode .activeClass {
  border-bottom: 2px solid var(--darkModeActiveLinkColor) !important;
}
.dark-mode .aboutProgress {
  background-color: var(--darkModeActiveLinkColor) !important;
}
.dark-mode .notFoundContainer svg {
  filter: invert();
}
.dark-mode .notFoundContainer a {
  color: var(--darkModeActiveLinkColor) !important;
}
.dark-mode section.input {
  border-bottom: 1px solid var(--darkModeBorderColor) !important;
}
.dark-mode .left-sidebar {
  border-right: 1px solid var(--darkModeBorderColor) !important;
}
.dark-mode .homepage .box {
  border: 1px solid var(--darkModeBorderColor) !important;
}
.dark-mode .homepage .box:hover {
  background-color: #232323 !important;
}
.dark-mode .homepage .coming-soon {
  background-color: transparent !important;
  color: #4c4c4c !important;
}
.dark-mode .weightVolumeRelations .titles div {
  border-bottom: 1px solid var(--darkModeBorderColor) !important;
}
.dark-mode .modal {
  background-color: #434343 !important;
  color: #b8b8b8 !important;
  border: 1px solid var(--darkModeBorderColor) !important;
}
.dark-mode .calculationContainer:hover {
  background-color: #f1f1f121 !important;
}
.dark-mode .adminMenu {
  border: 1px solid var(--darkModeBorderColor) !important;
}
.dark-mode .adminContent {
  border: 1px solid var(--darkModeBorderColor) !important;
}
.dark-mode .UserSummary {
  border: 1px solid var(--darkModeBorderColor) !important;
}
.dark-mode .SignupVsTime {
  border: 1px solid var(--darkModeBorderColor) !important;
}
.dark-mode .userTableHeader {
  border-top: 1px solid var(--darkModeBorderColor) !important;
  border-bottom: 1px solid var(--darkModeBorderColor) !important;
}
.dark-mode .userTableRow {
  border-bottom: 1px solid var(--darkModeBorderColor) !important;
}
.dark-mode .emailSendBox {
  border: 1px solid var(--darkModeBorderColor) !important;
}
/* DARK MODE ENDS*/

a,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.container {
  margin: 0 auto;
  display: flex;

  flex-grow: 1;
  text-align: center;
  width: 100%;
}

@media (max-width: 500px) {
  .container {
    flex-direction: column;
  }
}

/*SIDEBAR STARTS*/
.sidebar-container {
  padding-top: 10px;
  position: relative;
}
.sidebar-close-icon {
  right: 3px;
  top: 11px;
  position: absolute;
  cursor: pointer;
}

.sidebar-open-icon {
  width: 35px;
  height: 35px;
  cursor: pointer;
  position: absolute;
  z-index: 1;
}

.left-sidebar {
  flex: 1;
  border-right: 1px solid var(--border-color);
  display: flex;
  flex-direction: column;
  min-width: 200px;
}

.calculationContainer {
  display: flex;
  justify-content: space-between;
}

.calculationContainer:hover {
  background-color: #dfdfdf;
  transition: 250ms;
}
.calculation-box {
  max-height: 400px;
  overflow-y: scroll;
}
.calculation-box::-webkit-scrollbar {
  display: none !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.underline-bold {
  text-decoration: underline;
  font-weight: bold;
}

.calculationContainer .no {
  flex: 1;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
}
.calculationContainer .name {
  flex: 2;
  word-break: break-all;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  display: flex;
}
.calculationContainer .checkbox {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.sidebar-buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  gap: 15px;
}
.sidebar-buttons {
  width: 80%;
  margin-bottom: 10px;
}

.basic-sidebar-warning {
  display: flex;
  justify-content: center;
}

/*SIDEBAR ENDS*/

.right-calculation {
  flex: 5;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}

h1 {
  font-size: 24px;
  font-weight: bold;

  padding: 10px 40px;
}
h2 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.logo {
  margin-left: 25px;
  display: flex;
}
.logoRight {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-evenly;
  font-size: 20px;
}
.logo img {
  height: 50px;
  vertical-align: middle;
}

/* HEADER STARTS*/
.appHeader {
  padding: 10px 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: #000000;
  border-bottom: 1px solid var(--border-color);
}
.activeClass {
  border-bottom: 2px solid var(--muiTextColor);
}
.activeClass a {
  color: var(--muiTextColor) !important;
}
.appHeader .logo {
  font-weight: bold;
  color: #23282f;
}
.header-signup-button a {
  color: #0bcd56 !important;
}
.headerAdminLink {
  position: relative;
}
.headerAdminLinkIcon {
  position: absolute;
  top: 10px;
  right: 0;
}
/* HEADER ENDS*/

/* NAVBAR STARTS*/
.navbar-links ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-links li {
  list-style: none;
  white-space: nowrap;
}

.navbar-links li a {
  text-decoration: none;
  color: black;
  padding: 1rem 1.5rem;
  display: block;
}

.toggle-button {
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  display: none;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

@media (max-width: 900px) {
  .toggle-button {
    display: flex;
  }

  .navbar-links {
    display: none;
    width: 100%;
  }

  .appHeader {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links ul {
    width: 100%;
    flex-direction: column;
  }

  .navbar-links li {
    text-align: center;
  }

  .navbar-links li a {
    padding: 0.5rem 1rem;
  }

  .active {
    display: flex;
  }
}

/* NAVBAR ENDS*/
/*HOMEPAGE STARTS*/
.homepage {
  text-align: center;
  flex-grow: 1;
}
.verticallyAlignParent {
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.homepage a {
  text-decoration: none;
  color: #000000;
}
.homepage a:hover {
  text-decoration: none;
}
.homepage .hompage-links-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 15px;
  padding: 10px;
}
.homepage .hompage-searchbox-container {
  margin: 0 20px;
  padding: 20px 0;
}
.hompage-searchbox-container input {
  text-align: center;
}

.homepage .box {
  width: 100%;
  max-width: 250px;
  min-height: 175px;

  border: 1px solid var(--border-color);
  padding: 10px 5px;

  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.homepage .box:hover .homepageButtonContainer {
  display: block;
  display: flex;
  justify-content: space-evenly;

  gap: 10px;
  margin-top: auto;
}
.homepage .box .favoriteIcon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.homepage .box .favoriteIcon svg:hover {
  scale: 1.45;
  transition: scale 100ms ease-in-out;
}

.homepageButtonContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  gap: 10px;
  display: none;
}
.compactViewContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.coming-soon .description {
  align-items: center !important;
}
.homepage .coming-soon {
  color: #a2a0a1;
  background-color: #f1f1f1;
  flex-wrap: wrap;
}
.homepage .releaseDate {
  display: flex;
  flex-direction: row;
  flex: none !important;
}
.box .releaseDate {
  margin-top: 15px;
}
.homepage .box:hover {
  background-color: #eaf2f5;
}
.homepage .coming-soon:hover {
  background-color: #e9e9e9;
}
.homepage .title {
  font-weight: 600;
  margin-bottom: 8px;
}

.homepage .no-match-warning {
  color: #da6327;
  padding: 10px 20px;
  margin-bottom: 50px;
}

/* HOMEPAGE ENDS */

/* FOOTER STARTS*/
footer {
  margin: 10px auto 0 auto;
  text-align: center;
  padding: 10px 0;
  font-weight: bold;
  border-top: 1px solid var(--border-color);
  width: 100%;
}
footer a,
footer a:visited,
footer a:active {
  text-decoration: none;
  color: #000000;
}
footer a:hover {
  text-decoration: underline;
}
footer .footerLine {
  display: flex;
  text-align: center;
  flex-wrap: wrap;
}
footer .footerSection {
  flex: 1;
  min-width: 250px;
}
footer .footerSection .title {
  margin-bottom: 10px;
}
footer .trademark {
  font-size: 12px;
  text-align: center;
  justify-content: center;
}
footer .trademark span {
  font-size: 10px;
}
footer .linkWrapper {
  width: max-content;
  margin: 0 auto 10px auto;
  font-size: 12px;
}
footer .social-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
}
footer .social {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}
footer .social a {
  flex: 1;
  width: 100%;
}
footer .social img {
  height: 25px;
  width: 25px;
  flex: 1;
}
footer .emailSubscribe {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1024px) {
  .footerSection {
    margin-bottom: 15px;
  }
}
/* FOOTER ENDS*/
form {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  justify-content: space-evenly;
  align-items: center;
  gap: 15px;
}
.sptForm {
  gap: 3px;
}

.SPTbuttonContainer {
  padding: 0 20px;
}

.horizontalScroll {
  overflow-x: scroll;
}

.form-input-line .inputBox {
  flex: 1;
}
label {
  text-align: center;
  margin-bottom: 5px;
  display: block;
  white-space: nowrap;
}
/* LOADING STARTS*/
.centerLoading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.loadingRelative {
  position: relative;
}
.loadingAbsolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
}
/* LOADING ENDS*/

input {
  text-align: center;
}

.inputBox {
  margin: 3px;
  flex: 1;
}

section.input {
  border-bottom: 1px solid var(--border-color);
}

.buttonContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

/*LOGIN STARTS*/

.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin: 0px auto;
  padding: 20px 0;
  width: 100%;
  flex-grow: 1;
}

.login form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*LOGIN ENDS*/

/*CONTACT STARTS*/
.contact {
  padding: 0 1rem;
  margin: 10px auto;
  justify-content: center;
}

.contactContainer {
  margin: 0 auto;
  min-width: 300px;
  max-width: 500px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.contactContainer p {
  text-align: justify;
  line-height: 25px;
}

.contactInfo {
  display: flex;
  padding: 1rem;
  width: 100%;
  min-width: 300px;
  max-width: 500px;
  justify-content: space-evenly;
}
.contactForm {
  width: 100%;
  min-width: 300px;
  max-width: 500px;
}
.infoBox {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
}
#contact-form {
  padding: 0;
}

.contact form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactButtonContainer {
  width: 100%;
}

.captcha {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact button {
  margin-top: 15px;
}
.contact img {
  width: 30px;
}
.contact .inputError {
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.5em red;
  border-color: red !important;
}

.contact .refreshIcon {
  cursor: pointer;
}

.numberContainer {
  width: 50%;
}

/*CONTACT ENDS*/
/*SIGNUP STARTS*/
.signup {
  text-align: center;
  margin: 0px auto;
  padding: 20px 0;
  width: 100%;
  flex-grow: 1;
}

.signup form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*SIGNUP ENDS*/

/* LAYERS START --- LAYERS FOR SPT CORR, LIQUEAFACTION, LAYERED SOILS*/
.layer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 2px;
}

.labelContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 2px;
  margin-bottom: 5px;
}
.labelContainer label {
  text-align: center;
  width: 100%;
}
.layered-soil-layer span,
.layered-soil-layer input,
.layered-soil-layer label {
  flex: 1;
  padding: 5px;
}

.layered-soil-layer label {
  font-weight: 600;
  border-bottom: 1px solid #000000;
}
.layeredSoilOutputContainer {
  display: flex;
}
.layeredSoilOutput {
  flex: 1;
}
.layeredSoilOutput .layered-soil-layer:last-child {
  font-weight: 600;
  color: #19ba07;
}
.layeredSoilOutputSection {
  padding: 10px;
}
/* LAYERS ENDS*/
/* NOT FOUND STARTS*/
.notFoundContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5rem;
  flex-grow: 1;
  text-align: center;
}
.notFoundContainer h1 {
  padding: 1rem;
  font-size: 42px;
}
.notFoundContainer h2 {
  padding: 1rem;
  font-size: 24px;
}
.notFoundContainer p {
  font-size: 16px;
  line-height: 40px;
}
.notFoundContainer a {
  color: var(--muiTextColor) !important;
}
.notFoundImage {
  min-width: 300px;
  max-width: 800px;
}
.notFoundContent {
  min-width: 300px;
  max-width: 600px;
}
/* NOT FOUND ENDS*/
/* PRIVACY STARTS */
.privacyPolicy {
  padding: 15px;
  text-align: justify;
  flex-grow: 1;
}
.privacyPolicy h1 {
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
}
.privacyPolicy h2 {
  font-weight: bold;
  margin-top: 20px;
}
.privacyPolicy p {
  margin-top: 5px;
}

/* ABOUT ENDS */

.modal {
  box-shadow: 5px 5px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  padding: 3rem;
  position: relative;
  border: 1px solid var(--border-color);
  color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.modalCloseIcon {
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.modal .buttonContainer button {
  margin: 10px auto;
}
.modal .text {
  margin-bottom: 20px;
}

.consolidationResultsTable {
  margin: 15px 10px;
  display: flex;
}
.consolidationResultsTable .cell {
  padding: 10px;

  flex: 1;
}
.consolidationResultsTable span {
  display: block;
}
.consolidationResultsTable .cell span:first-child {
  border-bottom: 1px solid #000000;
  padding: 5px 0;
  margin-bottom: 10px;
  font-weight: bold;
}
.formulaBox {
  margin-top: 10px;
}
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #85797d #ffffff;
}
.dark-mode {
  scrollbar-color: #000000 #000000 !important;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

.dark-mode ::-webkit-scrollbar-track {
  background: var(--darkModeBgColor) !important;
}

*::-webkit-scrollbar-thumb {
  background-color: #85797d;
  border-radius: 17px;
  border: 3px solid #ffffff;
}

.dark-mode ::-webkit-scrollbar-thumb {
  background-color: var(--darkModeBgColor);
  border-radius: 17px;
  border: 1px solid var(--border-color);
}

.calculation-search {
  margin-top: 5px;
  padding: 5px;
}

#search::-webkit-search-cancel-button {
  cursor: pointer;
}
/*ACCOUNT STARTS*/
.MuiTabs-scrollButtons.Mui-disabled {
  opacity: 0.3;
}

.account {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0px auto;
  padding: 10px 0;
  flex-grow: 1;
}

.account form {
  display: flex;
  flex-direction: column;
}
.account label {
  display: block;
  text-align: left;
}

.account h1 {
  margin: 10px 0;
}
.tooltipContainer div {
  padding: 0.25rem;
  line-height: 1rem;
}
.account-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
  width: 100%;
  padding: 20px 0;
  border-bottom: 1px solid var(--border-color);
}
.account-section h4 {
  margin-bottom: 10px;
}
.account-section:first-of-type {
  border-top: 1px solid var(--border-color);
}
.account-section:last-of-type {
  border-bottom: none;
}
.account-section h3 {
  margin-bottom: 10px;
}
.emailPreferencesRow {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}
.emailPreferencesLabel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}
/*ACCOUNT ENDS*/
/* LOADER STARTS */
.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/* LOADER ENDS */

/*PRICING STARTS*/
.pricing {
  text-align: center;
  margin: 0px auto;
  padding: 20px 0;
  width: 100%;
  flex-grow: 1;
}

.pricingContainer {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}
.pricingContainer a {
  padding: 1rem;
  width: 100%;
  border-radius: 10px;
}

.pricingContainer .currencySymbol {
  font-size: 22px;
}
.pricingContainer .box {
  position: relative;
  background-color: #bcb9b944;
  border-radius: 15px;
  min-width: 300px;
  max-width: 100%;
}

.pricingContainer .box div {
  width: 100%;
  padding: 1rem;
  align-self: center;
}
.pricingContainer .box p {
  margin-bottom: 30px;
  text-align: left;
}
.pricingContainer .box p:last-child {
  margin-bottom: 0px;
}
.pricingContainer .box .title {
  color: white;
  font-weight: 600;
  padding: 20px;
  border: none;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.pricingContainer .box .price {
  font-size: 30px;
  padding-bottom: 0;
}
.pricingContainer .price-button-container {
  display: flex;
  width: 75%;
  justify-content: center;
}
.pricingContainer button {
  border: none;
  padding: 14px 0;
  width: 100%;
  border-radius: 10px;
  color: white !important;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 5px;
}
.pricingInfoModalButton {
  color: white !important;
}
.dark-mode .pricingInfoModalButton {
  color: #434343 !important;
}
.pricingButtonNoLink {
  padding: 14px 0;
  filter: brightness(70%);
}
.pricingContainer button:hover {
  filter: brightness(85%);
}
.pricingButtonNoLink:hover {
  filter: brightness(70%) !important;
}
.pricingContainer a {
  text-decoration: none;
  color: white;
}

.dark-mode .pricingContainer a {
  text-decoration: none;
  color: white !important;
}
.pricingContainer form {
  padding: 0;
}
.pricingContainer .box .basic {
  background-color: #8dc4e2;
}

.pricingContainer .box .standard {
  background-color: #6ea76f;
}

.pricingContainer .box .premium {
  background-color: #7f3b5d;
}

.pricingContainer .box .professional {
  background-color: #d55f5f;
}

.pricingContainer span {
  font-size: 14px;
}
/*PRICING ENDS*/

/* pilerocksettle starts*/
.pilerocksettle .columnWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.pilerocksettle .columnWrapper div {
  padding: 20px;
}
.definitions p {
  padding: 10px 0;
}

.pilerocksettleImage img {
  min-width: 200px;
  max-width: 300px;
  width: 100%;
}

.pilerocksettleInputs .inputContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 15px;
}

.definitions {
  min-width: 300px;
  text-align: left;
}
.pilerocksettle p {
  padding: 20px;
}
/* pilerocksettle ends*/

/*TERZAGHI STARTS*/

.disabled-inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px;
  gap: 1rem;
}

.enough {
  color: #38b805;
}
.not-enough {
  color: #e83838;
}
.formula-group {
  margin-bottom: 40px;
}

.formula-group .formulaBox {
  margin-bottom: 15px;
}

/* MEYERHOF STARTS*/
.factors-wrapper {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 20px;
}

.factors-wrapper .meyerhof-factors {
  min-width: 300px;
  background-color: #f7f7f71c;
  text-align: center;
  border: 1px solid #dcdcdca8;
  border-radius: 10px;
  padding: 10px;
}

.factors-wrapper .meyerhof-factors p {
  margin-top: 10px;
}
.meyerhof-factors h3 {
  font-size: 16px;
  margin: 20px auto 10px auto;
}

.meyerhof-result h3 {
  font-size: 16px;
  margin: 20px auto 10px auto;
}
/* MEYERHOF ENDS*/
/*PILE ROCK BC STARTS */

.notationContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
}

.pilerockbcNotations {
  white-space: break-spaces;
  text-align: left;
}
@media screen and (max-width: 1000px) {
  .pilerockbcFormulas,
  .pilerockbcNotations {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    align-items: center;
  }
  .small-font {
    display: flex;
    justify-content: center;
  }
}
.formulaLine {
  padding: 10px;
  justify-content: space-between;
}

.formulaLine div {
  text-align: center;
}
.small-font {
  font-size: 12px;
}

/* pilerockbc output*/
.pilerockbc .result-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
}
.pilerockbc .column {
  min-width: 300px;
  flex: 1;
}
.pilerockbc h3 {
  margin: 15px 0 5px 0;
  font-size: 16px;
}
.pilerockbc p {
  margin-bottom: 7px;
}
/*PILE ROCK BC ENDS */
.list-transition-enter,
.list-transition-appear {
  /*We give the list the initial dimension of the list button*/
  top: 0;
  width: 120px;
  max-height: 40px;
  color: transparent;
  background-color: #5a564c;
}
/* This is where we can add the transition*/
.list-transition-enter-active,
.list-transition-appear-active {
  top: 45px;
  width: 200px;
  max-height: 200px;
  background-color: #9e8949;
  transition: all 400ms;
}
/* CALCULATION SKETCH MODAL STARTS*/
.transitionModalsSketch {
  text-align: center;
}
.transitionModalImage img {
  max-width: 550px;
}
@media (max-width: 550px) {
  .transitionModalImage img {
    max-width: 450px;
  }
}
@media (max-width: 450px) {
  .transitionModalImage img {
    max-width: 350px;
  }
}
@media (max-width: 350px) {
  .transitionModalImage img {
    max-width: 250px;
  }
}
/* CALCULATION SKETCH MODAL ENDS*/
/* FORGOT PASSWORD STARTS*/
.forgotPassword {
  width: 300px;
  margin: 0 auto;
  text-align: justify;
}
/* FORGOT PASSWORD ENDS*/
/* ACCOUNT-MANAGE SUBSCRIPTION STARTS*/
.subscriptionDetails {
  padding: 0 20px;
  width: 100%;
  min-width: 300px;
  max-width: 600px;
}
.statsWrapper {
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
}
.usage-colored {
  color: #2d7dcd !important;
}
.chartContainer {
  position: relative;
  margin-top: 60px;
  margin-bottom: 45px;
  max-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.chartContainer .chart {
  position: relative;
}
.chartContainer text {
  pointer-events: none;
}
.chartContainer .chart .chartTitle {
  color: #2d7dcd;
  word-spacing: 100vw;
  position: absolute;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.chartInfo {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
.chartInfo p {
  margin-bottom: 10px;
}
.chartInfo p:first-of-type {
  margin-bottom: 20px;
  text-decoration: underline;
}
.usedDot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  background-color: #729eca;
}
.remainingDot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  background-color: #2d7dcd;
}
.remainingCriticalDot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  background-color: #b33a3a;
}
.chartHelper {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  gap: 20px;
  top: -40px;
  left: 0;
}
.chartHelper p {
  font-size: 14px;
  padding: 5px;
  display: flex;
  align-items: center;
}
.chartHelperText {
  margin-left: 5px;
}
.usageUpgrade {
  padding: 15px;
  margin-top: 25px;
  text-align: center;

  background: rgba(241, 163, 163, 0.127);
}
.usageUpgrade h3 {
  color: #db5d5d;
  margin-bottom: 15px;
}
.usageUpgrade p {
  margin-bottom: 15px;
}

/* ACCOUNT-MANAGE SUBSCRIPTION ENDS*/
.subscriptMargin {
  display: block;
  margin-top: -5px;
}

.calculationErrorMessage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px auto;
  text-align: left;
}

.calculationErrorMessage div {
  padding: 0 5px;
}
.calculationErrorMessage .icon {
  color: #e06262;
}
.calculationErrorMessage .message {
  color: #e83838;
}
.calculationErrorMessageHeader {
  margin-top: 20px;
  font-size: 18px;
  color: #e83838;
}
.checks h3 {
  margin: 15px auto 10px auto;
}

/* ADMIN STARTS */

.adminContainer h1,
.adminContainer h4 {
  margin: 0;
  text-align: center;
  padding: 0.7rem 0;
}

.adminContentContainer {
  margin-top: 0.5rem;
  display: flex;
  gap: 0.5rem;
}
.adminHeader {
  text-align: center;
  padding: 1.5rem;
  border-bottom: 1px solid #d4d4d4;
  margin-bottom: 0.5rem;
}

.adminMenu {
  position: relative;
  flex: 1;
  min-width: 180px;
  padding: 25px 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 1px solid #d4d4d4;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.adminMenuIcon {
  top: 10px;
  left: 10px;
  position: absolute;
  cursor: pointer;
}
.adminMenuCloseButton {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.adminMenu .listItem {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 10px;
  border-radius: 10px;
  gap: 7px;
}
.adminMenu .listItem:hover {
  background-color: #2d7dcd64;
}
.adminContent {
  position: relative;
  flex: 8;
  text-align: center;
  border: 1px solid #d4d4d4;
  border-right: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.userTableHeader {
  display: flex;
  padding: 1rem;
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  font-weight: 600;
}
.userTableRow {
  display: flex;
  border-bottom: 1px solid #d4d4d4;
  padding: 0.75rem;
  cursor: pointer;
}
.userTableHeader div {
  flex: 0.8;
}
.userTableRow div {
  flex: 0.8;
}

.userTableHeader div:nth-last-child(1) {
  flex: 0.2;
}
.userTableRow div:nth-last-child(1) {
  flex: 0.2;
}

.userTableHeader div:nth-last-child(3) {
  flex: 0.2;
}
.userTableRow div:nth-last-child(3) {
  flex: 0.2;
}

.userTableHeader div:first-child {
  flex: 0.2;
}
.userTableRow div:first-child {
  flex: 0.2;
}

.userTableRow:hover {
  background-color: #dfdfdf17;
}
.userTableEntry {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.userModalContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.userModalContainer-wrapper {
  display: flex;
  gap: 40px;
}
.userModalContainer-Left {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.userModalContainer-Right {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.adminUsers {
  padding: 0px 20px 20px 20px;
}
.adminCalculations {
  padding: 0px 20px 20px 20px;
}
.adminUsersSearchButton {
  margin: 20px auto;
}

.AdminUserInfo {
  margin: 0 auto;
  text-align: center;
}
.AdminUserInfo .success {
  color: #19ba07;
}
.AdminUserInfo .fail {
  color: #ff0000;
}
/* ADMIN -- DASHBOARD STARTS */

.adminDashboardContainer .flex {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 20px;
}
.UserSummary {
  width: 250px;
  /* height: 100%; */
  padding: 0.5rem;
  background-color: #e6eaf60e;
  border: 1px solid #d4d4d4;
  border-radius: 0.5rem;
}
.summaryContainer {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
.summaryContainer .icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.summaryContainer svg {
  font-size: 3rem;
}
.summary .number {
  font-size: 30px;
  text-align: left;
}
.summary .text {
  font-size: 14px;
  text-align: left;
}
.activeUsersBar .barTexts {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  padding-bottom: 5px;
}
.UserSummary .activeUsersBar {
  padding: 0.5rem;
}
.SignupVsTime {
  padding: 0.5rem;
  position: relative;
  width: 600px;
  height: 100%;
  background-color: #e6eaf60e;
  border: 1px solid #d4d4d4;
  border-radius: 0.5rem;
}

.selectPeriod {
  position: absolute;
  top: 10px;
  left: 10px;
}
.selectCalculation {
  position: absolute;
  top: 10px;
  right: 10px;
}
.SignupVsTime .icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.SignupVsTime .icon svg {
  font-size: 3rem;
}
.totalCalculationCounts {
  min-width: 600px;
}
.totalCalculationCountsTitles {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.totalCalculationCountsTitles div {
  padding: 0.1rem;
}
.adminCalculations {
  display: flex;
  gap: 20px;
  justify-content: space-around;
  flex-wrap: wrap;
}

.adminImage {
  width: 100px;
  height: 50px;
}
.emailSendBoxContainer {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}
.emailSendBox {
  border: 1px solid var(--border-color);
  padding: 1rem;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
.emailSendBox h2 {
  text-align: center;
}
.emailSendBox .definition {
  text-align: left;
}
.emailSendBox .button {
  margin-top: 1rem;
}
.mailSummary {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  font-size: 12px;
}

/* ADMIN -- DASHBOARD ENDS */
/* ADMIN ENDS */

/* EMAIL CONFIRM STARTS*/
.emailConfirm {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.emailConfirm .emailConfirmSuccessContainer,
.emailConfirmFailContainer {
  padding: 2rem;
}
.emailConfirmInfoSuccess {
  color: #31c80fd5;
}
.emailConfirmInfoFail {
  color: #e70707d5;
}
/* EMAIL CONFIRM ENDS */
/* ABOUT US STARTS */
.aboutUs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0px auto;
  height: calc(9 * var(--aboutUsVerticalInterval));
  text-align: center;
  position: relative;
  flex-grow: 1;
}

.aboutUs .aboutProgress {
  position: relative;
  margin: 1rem auto;
  display: flex;
  height: calc(8 * var(--aboutUsVerticalInterval));
  width: 5px;
  border-radius: 5px;
  background-color: var(--muiTextColor);
}

.aboutUs .right {
  width: 150px;
  position: absolute;
  left: 50px;
  border-bottom: 2px solid var(--muiTextColor);
  padding: 0.2rem 0.3rem;
  background-color: white;
  text-align: left;
}
.aboutUs .left {
  width: 150px;
  position: absolute;
  right: 50px;
  border-bottom: 2px solid var(--muiTextColor);
  padding: 0.2rem 0.3rem;
  text-align: right;
  background-color: white;
}
.aboutUs .center {
  position: absolute;
  left: -48px;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  width: 100px;
  color: var(--muiTextColor);
  background-color: white;
}
.aboutUs p {
  max-width: 500px;
  text-align: justify;
}
.aboutUs #level-1 {
  top: calc(0 * var(--aboutUsVerticalInterval));
}
.aboutUs #level-2 {
  top: calc(1 * var(--aboutUsVerticalInterval));
}
.aboutUs #level-3 {
  top: calc(2 * var(--aboutUsVerticalInterval));
}
.aboutUs #level-4 {
  top: calc(3 * var(--aboutUsVerticalInterval));
}
.aboutUs #level-5 {
  top: calc(4 * var(--aboutUsVerticalInterval));
}
.aboutUs #level-6 {
  top: calc(5 * var(--aboutUsVerticalInterval));
}
.aboutUs #level-7 {
  top: calc(6 * var(--aboutUsVerticalInterval));
}
.aboutUs #level-8 {
  top: calc(7 * var(--aboutUsVerticalInterval));
}
.aboutUs #level-9 {
  top: calc(8 * var(--aboutUsVerticalInterval));
}
.aboutUs #level-10 {
  top: calc(9 * var(--aboutUsVerticalInterval));
}

/* ABOUT US ENDS */
/* Weight Volume Relations STARTS */
.weightVolumeRelations {
  padding: 10px 10px 0 10px;
}
.weightVolumeRelations .inputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
}
.weightVolumeRelations label {
  margin-bottom: 0;
}
.weightVolumeRelations .inputRow {
  height: 75px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  flex-wrap: nowrap;
  gap: 2rem;
}
.weightVolumeRelations .titles div {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 0.75rem;
  gap: 5px;
}
.weightVolumeRelations .calculationName {
  min-width: 150px;
  display: flex;
  justify-content: flex-start;
}
.weightVolumeRelations .checkboxes {
  min-width: 120px;
  display: flex;
  justify-content: flex-start;
}
.weightVolumeRelations .selects {
  min-width: 200px;
}
.weightVolumeRelations .inputs {
  min-width: 360px;
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
}
.weightVolumeRelations .formula {
  min-width: 250px;
}

.weightVolumeResultContainer {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.weightVolumeResultBox {
  min-width: 300px;
  padding: 1rem;
  background-color: #f7f7f71c;
  border: 1px solid #dcdcdca8;
  border-radius: 10px;
}
.weightVolumeResultBox p {
  padding: 0.5rem;
}
.weightVolumeResultBox h3 {
  margin-bottom: 1rem;
}
/* Weight Volume Relations ENDS */

/* STROUD STARTS */
.stroudContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  gap: 2rem;
  flex-wrap: wrap;
}
.stroudBox {
  padding: 1rem;
  background-color: #f7f7f71c;
  border: 1px solid #dcdcdca8;
}
.stroudBox img {
  width: 100%;
  height: 100%;
}
.stroudBox p {
  margin: 0.5rem;
}
.stroudf1GraphContainer {
  width: 300px;
  position: relative;
}
.stroudf1VerticalLine {
  position: absolute;
  border: 2px solid #b33a3a;
  background-color: #b33a3a;
  bottom: 42px; /* CONSTANT */
}
.stroudf1HorizontalLine {
  position: absolute;
  border: 2px solid #b33a3a;
  background-color: #b33a3a;
  left: 48px; /* CONSTANT */
}
.f1Value {
  position: absolute;
  font-weight: bold;
  color: #b33a3a;
  border: 1px solid #b33a3a;
  font-size: 10px;
  padding: 2px;
  left: 50px; /* CONSTANT */
}
.stroudf2GraphContainer {
  width: 300px;
  position: relative;
}
.stroudf2VerticalLine {
  position: absolute;
  border: 2px solid #b33a3a;
  background-color: #b33a3a;
  bottom: 40px; /* CONSTANT */
}
.stroudf2HorizontalLine {
  position: absolute;
  border: 2px solid #b33a3a;
  background-color: #b33a3a;
  left: 52px; /* CONSTANT */
}
.f2Value {
  position: absolute;
  color: #b33a3a;
  border: 1px solid #b33a3a;
  font-size: 10px;
  padding: 2px;
  left: 55px; /* CONSTANT */
  font-weight: bold;
}
/* STROUD ENDS */

/* CLAYEFFECTIVE STARTS */
.clayEffectiveContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  gap: 2rem;
}
.clayEffectiveBox {
  padding: 1rem;
  background-color: #f7f7f71c;
  border: 1px solid #dcdcdca8;
}
.clayEffectiveBox img {
  width: 100%;
  height: 100%;
}
.clayEffectiveBox p {
  margin: 0.5rem;
}
.clayEffectiveGraphContainer {
  min-width: 400px;
  max-width: 600px;
  position: relative;
}
.clayEffectiveGraphContainer h4 {
  margin-bottom: 1rem;
}
/* CLAYEFFECTIVE ENDS */
/* STRESS INCREASE STARTS */
.stressIncreaseContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  gap: 2rem;
}
.stressIncreaseBox {
  padding: 1rem;
  background-color: #f7f7f71c;
  border: 1px solid #dcdcdca8;
}
.stressIncreaseBox img {
  width: 100%;
  height: 100%;
}
.dark-mode .stressIncreaseBox img {
  filter: invert(80%);
}
.stressIncreaseBox p {
  margin: 0.5rem;
}
.stressIncreaseGraphContainer {
  min-width: 400px;
  max-width: 600px;
  position: relative;
}
.stressIncreaseGraphContainer h4 {
  margin-bottom: 1rem;
}
/* STRESS INCREASE ENDS */

/*THERY */
.theory {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0px auto;

  flex-grow: 1;
}
.theoryContainer {
  max-width: 1200px;
  width: 60%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 900px) {
  .theoryContainer {
    width: 90%;
  }
}
.theoryContainer h1 {
  text-align: center;
}
.theoryContainer h2 {
  margin-top: 20px;
  margin-bottom: 0;
}
.theoryContainer h3 {
  margin-top: 20px;
  margin-bottom: 0;
}
.theoryContainer h4 {
  margin-top: 20px;
}
.theoryContainer p {
  padding: 10px 0 10px 0;
}
.theoryContainer .theoryEquation {
  position: relative;
  text-align: center;
}

.theoryContainer .theoryEquation span {
  display: block;
  position: absolute;
  top: 50%;

  right: 0;
  transform: translateY(-50%);
  font-size: 12px;
}
.correctionFactorTable {
  border: 1px solid var(--border-color);
}
.correctionFactorTableRow {
  display: flex;
  justify-content: space-around;
}
.correctionFactorTableRow:last-of-type {
  border-bottom: none;
}
.correctionFactorTableColumn {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-right: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  padding: 3px 0;
}
.correctionFactorTableColumn:last-of-type {
  border-right: none;
}
.no-border-bottom {
  border-bottom: none !important;
}
.theoryButtonContainer {
  margin: 20px auto 0 auto;
  text-align: center;
}
.theoryComingSoon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  text-align: center;
  padding: 5rem 0;
}
.citation {
  font-size: 12px;
  text-align: right;
}
